import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useDispatch } from "react-redux";
import { setCategory } from "../../store/slice";
import { FiMenu } from "react-icons/fi";
import { toast } from "react-toastify";

const TopNavbar = ({ buttonActive, setButtonActive }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(`${serverUrl}/get_categories.php`, requestOptions);
      const data = await response.json();
      dispatch(setCategory(data.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleButtonSubmit = () => {
    setButtonActive(!buttonActive);
    setSidebarVisible(!sidebarVisible);
  };

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handlePassword = () => {
    navigate("/change-password");
    setIsDropdownOpen(false);
  };

  const handleSearch = async () => {
    if (searchText.trim() === "") {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${serverUrl}/search.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify({ keyword: searchText }),
      });
      const data = await response.json();
      if (!data.error) {
        setSearchText("");
        navigate("/search-results", {
          state: {
            data: data.data,
          },
        });
      } else {
        toast.error(data.error_msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="topbar">
      <nav className={`navbar-custom ${sidebarVisible ? "navbar-custom" : "sidebar-hidden"}`}>
        <ul className="list-unstyled topbar-nav float-right mb-0">
          <li className="dropdown">
            <a
              href="/"
              className="nav-link dropdown-toggle waves-effect waves-light nav-user"
              role="button"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown();
              }}
            >
              <span className="mr-4 nav-user-name hidden-sm">
                Admin <i className="mdi mdi-chevron-down"></i>{" "}
              </span>
            </a>
            {isDropdownOpen && (
              <div
                className="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="dropdownToggle"
              >
                <a
                  href="/"
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePassword();
                  }}
                >
                  <i className="dripicons-lock text-muted mr-2"></i>
                  Change Password
                </a>
                <div className="dropdown-divider"></div>
                <a
                  href="/"
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="dripicons-exit text-muted mr-2"></i> Logout
                </a>
              </div>
            )}
          </li>
        </ul>

        <ul className="list-unstyled topbar-nav mb-0">
          <li>
            <a href="/all-products">
              <span className="responsive-logo">
                <img
                  src="/GC_logo.png"
                  alt="logo-small"
                  className="logo-sm align-self-center"
                  height="34"
                />
              </span>
            </a>
          </li>
          <li>
            <button className={`button-menu-mobile nav-link ${buttonActive ? "active" : ""}`}>
              <FiMenu
                style={{ fontSize: "23px", color: "#000" }}
                onClick={() => handleButtonSubmit()}
              />
            </button>
          </li>
          <li className="hide-phone app-search">
            <input
              type="text"
              placeholder="Search All Products..."
              className="form-control"
              value={searchText}
              required
              onChange={(e) => setSearchText(e.target.value)}
            />
            <a
              className="bg-dark"
              onClick={() => {
                if (!loading) {
                  handleSearch();
                }
              }}
            >
              <i className="fas fa-search" style={{ color: "#fff" }}></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TopNavbar;
