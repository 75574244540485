import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LuLayoutDashboard, LuBarChart3 } from "react-icons/lu";
import { FiShoppingCart } from "react-icons/fi";
import { IoMdListBox } from "react-icons/io";
import { useSelector } from "react-redux";
import SearchByManufacturer from "../searchByCategory/index";

const LeftNavbar = ({ buttonActive }) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("products");
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [expandedSubmenu, setExpandedSubmenu] = useState(null);
  const categories = useSelector((state) => state.category.category);
  let pathArray;

  useEffect(() => {
    pathArray = window.location.pathname.split("/").filter(Boolean);
    if (pathArray.length > 0) {
      if (pathArray[0] === "products") {
        setIsActive(pathArray[0]);
        if (pathArray[1] !== "all") {
          setExpandedMenu(pathArray[1]);
        }
        if (pathArray[2] !== "all") {
          setExpandedSubmenu(pathArray[2]);
        }
      } else if (pathArray[0] === "manufacturer-products") {
        setIsActive("products");
        if (pathArray[1] !== "all") {
          setExpandedSubmenu(pathArray[1]);
        }
        if (pathArray[2] !== "all") {
          setExpandedMenu(pathArray[2]);
        }
      } else if (pathArray[0] === "all-products") {
        setIsActive("products");
      } else if (
        pathArray[0] === "existing-products" ||
        pathArray[0] === "removed-products" ||
        pathArray[0] === "sale-products"
      ) {
        setIsActive("existing-products");
      } else {
        setIsActive(pathArray[0]);
      }
    }
  }, [pathArray]);

  const toggleSubmenu1 = (menuName) => {
    setExpandedMenu(expandedMenu === menuName ? null : menuName);
  };

  const activeFucntion = (activeTab) => {
    setIsActive(activeTab);
  };

  const toggleSubmenu2 = (submenu) => {
    setExpandedSubmenu(expandedSubmenu === submenu ? null : submenu);
  };

  return (
    <div className={`leftbar-tab-menu ${buttonActive ? "" : "leftbar-tab-menu-show"}`}>
      <div className="main-icon-menu">
        <a href="/all-products" className="logo logo-metrica d-block text-center">
          <span>
            <img src="/GC_logo.png" alt="logo-small" className="logo-sm" />
          </span>
        </a>
        <nav className="nav">
          <a
            href="#dashboard"
            className="nav-link"
            data-toggle="tooltip-custom"
            data-placement="right"
            title="Dashboard"
            data-original-title="Ecommerce"
            data-trigger="hover"
            onClick={() => activeFucntion("dashboard")}
          >
            <LuLayoutDashboard style={{ fontSize: "26px" }} />
          </a>

          <a
            href="#Bti-Products"
            className="nav-link"
            data-toggle="tooltip-custom"
            data-placement="right"
            title="Existing"
            data-original-title="Apps"
            data-trigger="hover"
            onClick={() => {
              navigate("/all-products");
              activeFucntion("products");
            }}
          >
            <LuBarChart3 style={{ fontSize: "25" }} />
          </a>

          <a
            href="#Products-center"
            className="nav-link"
            data-toggle="tooltip-custom"
            data-placement="right"
            title="Products"
            data-original-title="UI Kit"
            data-trigger="hover"
            onClick={() => activeFucntion("existing-products")}
          >
            <FiShoppingCart style={{ fontSize: "25" }} />
          </a>

          <a
            href="#Orders-center"
            className="nav-link"
            data-toggle="tooltip-custom"
            data-placement="right"
            title="Orders"
            data-original-title="Pages"
            data-trigger="hover"
            onClick={() => activeFucntion("orders")}
          >
            <IoMdListBox style={{ fontSize: "26" }} />
          </a>
        </nav>
      </div>

      <div className={`main-menu-inner ${buttonActive ? "" : "main-menu-inner-hide"}`}>
        <div className="topbar-left">
          <a href="/all-products" className="logo">
            <span>
              <h4 className="mt-0 mb-3 mt-5">Garage Cycles</h4>
            </span>
          </a>
        </div>
        <div className="menu-body slimscroll">
          {isActive === "dashboard" && (
            <div id="dashboard" className="main-icon-menu-pane d-block">
              <div className="title-box">
                <h6 className="menu-title">Dashboard</h6>
              </div>
              <ul className="nav">
                <li className="nav-item">
                  <a
                    href="/"
                    className="nav-link"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/dashboard");
                    }}
                  >
                    Stats
                  </a>
                </li>
              </ul>
            </div>
          )}
          {/* 2nd point  */}
          {isActive === "products" && (
            <div id="products" className="main-icon-menu-pane">
              <SearchByManufacturer />
              <div className="title-box">
                <h6 className="menu-title">BTI Products</h6>
              </div>
              <ul className="nav metismenu">
                {categories.map((category) => (
                  <li
                    key={category.category_id}
                    className={`nav-item ${
                      expandedMenu === category.category_id ? "expanded" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleSubmenu1(category.category_id);
                        if (expandedMenu === category.category_id) {
                          setOpenSubmenu(null);
                        } else {
                          setOpenSubmenu(category.category_id);
                        }
                      }}
                    >
                      <span
                        className="w-100"
                        style={{
                          color: expandedMenu === category.category_id ? "#738be9" : "#5a5c61",
                        }}
                      >
                        {category.category_name}{" "}
                        <span className="badge bg-success text-white">
                          {category.percentage_margin}%
                        </span>
                      </span>

                      <span className="menu-arrow">
                        <i
                          key={category.category_id}
                          className={`mdi mdi-chevron-${
                            openSubmenu === category.category_id ? "down" : "right"
                          }`}
                        ></i>
                      </span>
                    </a>
                    <ul className="nav-second-level">
                      <li>
                        <a
                          href={`/products/${
                            category.category_id
                          }/all/all?category_name=${encodeURIComponent(
                            category.category_name
                          )}&sub_category_name=all&manufacturer_name=all`}
                          style={{
                            color:
                              expandedMenu === category.category_id && expandedSubmenu == null
                                ? "#738be9"
                                : "#5a5c61",
                          }}
                        >
                          All {category.category_name}
                        </a>
                      </li>
                      {category.subcategories.map((subcategory) => (
                        <li
                          className={`nav-second-level ${
                            expandedSubmenu === subcategory.sub_category_id ? "expended" : ""
                          }`}
                          key={subcategory.sub_category_id}
                        >
                          <a
                            onClick={() => toggleSubmenu2(subcategory.sub_category_id)}
                            href="#category"
                            style={{
                              color:
                                expandedSubmenu === subcategory.sub_category_id
                                  ? "#738be9"
                                  : "#5a5c61",
                            }}
                          >
                            {subcategory.sub_category_name}{" "}
                            <span className="badge bg-success text-white">
                              {subcategory.percentage_margin}%
                            </span>
                          </a>
                          <ul
                            className={`nav-third-level ${
                              expandedSubmenu === subcategory.sub_category_id ? "expanded" : ""
                            }`}
                          >
                            <li>
                              <a
                                href={`/products/${category.category_id}/${
                                  subcategory.sub_category_id
                                }/all?category_name=${encodeURIComponent(
                                  category.category_name
                                )}&sub_category_name=${encodeURIComponent(
                                  subcategory.sub_category_name
                                )}&manufacturer_name=all`}
                              >
                                All {subcategory.sub_category_name} Products
                              </a>
                            </li>
                            {subcategory.manufacturers.map((manufacturer, manufacturerIndex) => (
                              <li key={manufacturerIndex}>
                                <a
                                  href={`/products/${category.category_id}/${
                                    subcategory.sub_category_id
                                  }/${
                                    manufacturer.manufacturer_id
                                  }?category_name=${encodeURIComponent(
                                    category.category_name
                                  )}&sub_category_name=${encodeURIComponent(
                                    subcategory.sub_category_name
                                  )}&manufacturer_name=${encodeURIComponent(
                                    manufacturer.manufacturer_name
                                  )}`}
                                >
                                  {manufacturer.manufacturer_name}{" "}
                                  <span className="badge bg-success text-white">
                                    {manufacturer.percentage_margin}%
                                  </span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {isActive === "existing-products" && (
            <div id="MetricaUikit" className="main-icon-menu-pane d-block">
              <div className="title-box">
                <h6 className="menu-title">Products</h6>
              </div>
              <ul className="nav metismenu">
                <li
                  className={`nav-item ${expandedMenu === "Existing Products" ? "expanded" : ""}`}
                >
                  <a
                    className="nav-link"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleSubmenu1("Existing Products");
                      navigate("/existing-products");
                    }}
                  >
                    <span className="w-100">Existing Products</span>
                    <span className="menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </a>
                </li>
                <li className={`nav-item ${expandedMenu === "Removed Products" ? "expanded" : ""}`}>
                  <a
                    className="nav-link"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleSubmenu1("Removed Products");
                      navigate("/removed-products");
                    }}
                  >
                    <span className="w-100">Removed Products</span>
                    <span className="menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </a>
                </li>
                <li
                  className={`nav-item ${
                    expandedMenu === "Sales/Close-out-Product" ? "expanded" : ""
                  }`}
                >
                  <a
                    className="nav-link"
                    href="/"
                    onClick={(e) => {
                      toggleSubmenu1("Sales/Close-out-Product");
                      e.preventDefault();
                      navigate("/sale-products");
                    }}
                  >
                    <span className="w-100">Sales/Close out Product's</span>
                    <span className="menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          )}

          {/* 4th Point  */}
          {isActive === "orders" && (
            <div id="MetricaPages" className="main-icon-menu-pane d-block o">
              <div className="title-box">
                <h6 className="menu-title">Orders</h6>
              </div>
              <ul className="nav">
                <li className={`nav-item ${expandedMenu === "All-Orders" ? "expanded" : ""}`}>
                  <a
                    className="nav-link"
                    href="/"
                    onClick={(e) => {
                      toggleSubmenu1("All-Orders");
                      e.preventDefault();
                      navigate("/orders");
                    }}
                  >
                    <span className="w-100">All Orders</span>
                    <span className="menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          )}
          {isActive === "MetricaAuthentication" && (
            <div id="MetricaAuthentication" className="main-icon-menu-pane d-block">
              <div className="title-box">
                <h6 className="menu-title">Merchant Center</h6>
              </div>
              <ul className="nav">
                <li className={`nav-item ${expandedMenu === "GetProduct" ? "expanded" : ""}`}>
                  <a
                    className="nav-link"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/merchant-products");
                      toggleSubmenu1("GetProduct");
                    }}
                  >
                    <span className="w-100">Get Merchant Products</span>
                    <span className="menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeftNavbar;
