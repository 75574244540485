import React, { useEffect, useState } from "react";
import { Box, List, ListItem, Button, Menu, MenuItem } from "@mui/material";
import "./style.css";

const SearchByManufacturer = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [categories, setCategories] = useState([]);

  const [anchorElAlphabet, setAnchorElAlphabet] = useState(null);
  const [openAlphabetMenu, setOpenAlphabetMenu] = useState(false);
  const [selectedAlphabetIndex, setSelectedAlphabetIndex] = useState(-1);

  const [anchorElManufacturer, setAnchorElManufacturer] = useState(null);
  const [openManufacturerMenu, setOpenManufacturerMenu] = useState(false);
  const [selectedManufacturerIndex, setSelectedManufacturerIndex] = useState(-1);

  const [anchorElCategory, setAnchorElCategory] = useState(null);
  const [openCategoryMenu, setOpenCategoryMenu] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${serverUrl}/get_manufacturers.php`);
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSelectedAlphabetManufacturers = () => {
    if (selectedAlphabetIndex < 0) return [];
    return categories[selectedAlphabetIndex]?.manufacturers || [];
  };

  const getSelectedManufacturerCategories = () => {
    const manufacturers = getSelectedAlphabetManufacturers();
    if (selectedManufacturerIndex < 0) return [];
    return manufacturers[selectedManufacturerIndex]?.categories || [];
  };

  const getSelectedCategorySubcategories = () => {
    const catList = getSelectedManufacturerCategories();
    if (selectedCategoryIndex < 0) return [];
    return catList[selectedCategoryIndex]?.sub_categories || [];
  };

  const handleOpenAlphabetMenu = (event, index) => {
    setAnchorElAlphabet(event.currentTarget);
    setSelectedAlphabetIndex(index);
    setOpenAlphabetMenu(true);
    setOpenManufacturerMenu(false);
    setOpenCategoryMenu(false);
  };

  const handleCloseAlphabetMenu = () => {
    setAnchorElAlphabet(null);
    setOpenAlphabetMenu(false);
  };

  const handleOpenManufacturerMenu = (event, mIndex) => {
    setAnchorElManufacturer(event.currentTarget);
    setSelectedManufacturerIndex(mIndex);
    setOpenManufacturerMenu(true);
    setOpenCategoryMenu(false);
  };

  const handleCloseManufacturerMenu = () => {
    setAnchorElManufacturer(null);
    setOpenManufacturerMenu(false);
  };

  const handleOpenCategoryMenu = (event, cIndex) => {
    setAnchorElCategory(event.currentTarget);
    setSelectedCategoryIndex(cIndex);
    setOpenCategoryMenu(true);
  };

  const handleCloseCategoryMenu = () => {
    setAnchorElCategory(null);
    setOpenCategoryMenu(false);
  };

  return (
    <Box className="search-by-manufacturer">
      <List className="d-flex flex-wrap justify-content-center manufacturer-list">
        {categories.map((item, index) => (
          <ListItem key={index} disableGutters sx={{ width: "30px" }}>
            <Button
              className="item-text"
              variant="text"
              onClick={(e) => handleOpenAlphabetMenu(e, index)}
            >
              {item.alphabet.toUpperCase()}
            </Button>
          </ListItem>
        ))}
      </List>

      <Menu anchorEl={anchorElAlphabet} open={openAlphabetMenu} onClose={handleCloseAlphabetMenu}>
        {selectedAlphabetIndex >= 0 &&
          getSelectedAlphabetManufacturers().map((manufacturer, mIndex) => (
            <MenuItem
              key={manufacturer.manufacturer_id}
              onClick={(e) => {
                handleCloseAlphabetMenu();
                handleOpenManufacturerMenu(e, mIndex);
              }}
            >
              {manufacturer.name}
            </MenuItem>
          ))}
      </Menu>

      <Menu
        anchorEl={anchorElManufacturer}
        open={openManufacturerMenu}
        onClose={handleCloseManufacturerMenu}
      >
        {selectedManufacturerIndex >= 0 && (
          <>
            <MenuItem
              onClick={() => {
                const manf = getSelectedAlphabetManufacturers()[selectedManufacturerIndex];
                if (!manf) return;
                const href = `/manufacturer-products/${
                  manf.manufacturer_id
                }/all/all?manufacturer_name=${encodeURIComponent(
                  manf.name
                )}&category_name=all&sub_category_name=all`;
                window.location.href = href;
              }}
            >
              All {getSelectedAlphabetManufacturers()[selectedManufacturerIndex]?.name} Categories
            </MenuItem>

            {getSelectedManufacturerCategories().map((category, cIndex) => (
              <MenuItem
                key={category.category_id}
                onClick={(e) => {
                  handleCloseManufacturerMenu();
                  handleOpenCategoryMenu(e, cIndex);
                }}
              >
                {category.category_name}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>

      <Menu anchorEl={anchorElCategory} open={openCategoryMenu} onClose={handleCloseCategoryMenu}>
        {selectedCategoryIndex >= 0 && (
          <>
            <MenuItem
              onClick={() => {
                const manf = getSelectedAlphabetManufacturers()[selectedManufacturerIndex];
                const cat = getSelectedManufacturerCategories()[selectedCategoryIndex];
                if (!manf || !cat) return;

                const href = `/manufacturer-products/${manf.manufacturer_id}/${
                  cat.category_id
                }/all?manufacturer_name=${encodeURIComponent(
                  manf.name
                )}&category_name=${encodeURIComponent(cat.category_name)}&sub_category_name=all`;
                window.location.href = href;
              }}
            >
              All {getSelectedManufacturerCategories()[selectedCategoryIndex]?.category_name}{" "}
              Subcategories
            </MenuItem>

            {getSelectedCategorySubcategories().map((sub) => (
              <MenuItem
                key={sub.sub_category_id}
                onClick={() => {
                  const manf = getSelectedAlphabetManufacturers()[selectedManufacturerIndex];
                  const cat = getSelectedManufacturerCategories()[selectedCategoryIndex];
                  if (!manf || !cat) return;

                  const href = `/manufacturer-products/${manf.manufacturer_id}/${cat.category_id}/${
                    sub.sub_category_id
                  }?manufacturer_name=${encodeURIComponent(
                    manf.name
                  )}&category_name=${encodeURIComponent(
                    cat.category_name
                  )}&sub_category_name=${encodeURIComponent(sub.sub_category_name)}`;
                  window.location.href = href;
                }}
              >
                {sub.sub_category_name}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </Box>
  );
};

export default SearchByManufacturer;
