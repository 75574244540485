import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Orders = () => {
  const shopifyUrl = process.env.REACT_APP_SHOPIFY_URL;
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [nextUrl, setNextUrl] = useState(
    `/proxy.php?url=${encodeURIComponent(shopifyUrl + "/orders.json")}`
  );
  // const [nextUrl, setNextUrl] = useState(`${shopifyUrl}/orders.json`);
  const [hasFetched, setHasFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = (url) => {
    const headers = new Headers({
      "X-Shopify-Access-Token": "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",
      "Content-Type": "application/json",
    });

    fetch(url, { method: "GET", headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const linkHeader = response.headers.get("Link");
        if (linkHeader) {
          const nextPageLink = parseNextPageLink(linkHeader);
          setNextUrl(nextPageLink ? `/proxy.php?url=${encodeURIComponent(nextPageLink)}` : null);
          // setNextUrl(nextPageLink ? `http://localhost:8080/${nextPageLink}` : null);
        } else {
          setNextUrl(null);
        }

        return response.json();
      })
      .then((data) => {
        setData((prevData) => [...prevData, ...data.orders]);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(true);
      });
  };

  const parseNextPageLink = (linkHeader) => {
    if (!linkHeader) return null;

    const links = linkHeader.split(",");
    for (let link of links) {
      const [url, rel] = link.split(";").map((part) => part.trim());
      if (rel === 'rel="next"') {
        return url.slice(1, -1);
      }
    }
    toast.success("All Orders fetched successfully");
    return null;
  };

  useEffect(() => {
    if (nextUrl) {
      if (!hasFetched) {
        toast.info("Please Wait, Fetching Orders...");
        setHasFetched(true);
      }

      const fetchNextPage = setTimeout(() => fetchData(nextUrl), 1000);
      return () => clearTimeout(fetchNextPage);
    }
  }, [nextUrl]);

  const onGlobalFilterChange = (e) => {
    let value = e.target.value;
    value = value.trimStart().replace(/\s+/g, " ");

    setGlobalFilter(value);
  };

  return (
    <div className="row mt-2">
      <div className="col-12">
        <div className="card" style={{ overflowX: "auto" }}>
          <div className="card-body">
            <h4 className="mt-0 header-title">Order Products</h4>

            <div className="mb-3 flex justify-end">
              <span className="p-input-icon-left">
                <InputText
                  value={globalFilter}
                  onChange={onGlobalFilterChange}
                  placeholder="Search..."
                  className="p-inputtext-sm"
                />
              </span>
            </div>
            <DataTable
              value={data}
              paginator
              rows={10}
              loading={loading}
              rowsPerPageOptions={[5, 10, 25, 50]}
              globalFilter={globalFilter}
              emptyMessage="No orders found."
              className="border border-gray-300 rounded-lg shadow custom-table font-small no-wrap"
              showGridlines
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Column field="name" header="Order Name" />
              <Column
                header="Unfulfilled Items"
                body={(rowData) => {
                  const unfulfilledItems =
                    rowData.line_items?.filter((item) => !item.fulfilled && !item.refunded) || [];
                  return (
                    <ul>
                      {unfulfilledItems.length > 0
                        ? unfulfilledItems.map((item, index) => (
                            <li key={index}>{item.title || "Unnamed Item"}</li>
                          ))
                        : "No unfulfilled items"}
                    </ul>
                  );
                }}
              />
              <Column
                header="Order ID"
                body={(rowData) => (
                  <a
                    href={`https://www.bti-usa.com/public/item/${rowData.id}?track=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {rowData.id}
                  </a>
                )}
              />
              <Column field="current_subtotal_price" header="Subtotal Price" />
              <Column field="current_subtotal_price_set.shop_money.amount" header="Amount" />
              <Column field="customer.first_name" header="First Name" />
              <Column field="customer.last_name" header="Last Name" />
              <Column field="customer.default_address.address1" header="Customer Address" />
              <Column field="customer.default_address.city" header="Customer City" />
              <Column field="customer.default_address.province" header="Customer State" />
              <Column field="customer.default_address.zip" header="Customer Zip Code" />
              <Column field="shipping_address.name" header="Shipping Name" />
              <Column field="shipping_address.address1" header="Shipping Address" />
              <Column field="shipping_address.city" header="Shipping City" />
              <Column field="shipping_address.province" header="Shipping State" />
              <Column field="shipping_address.phone" header="Shipping Phone Number" />
              <Column field="shipping_address.zip" header="Shipping Zip Code" />
              <Column field="shipping_lines[0].code" header="Shipping Line" />
              <Column
                field="shipping_lines[0].discounted_price"
                header="Shipping Discounted Price"
              />
              <Column field="shipping_lines[0].price" header="Shipping Line Price" />
              <Column field="shipping_lines[0].source" header="Shipping Source" />
              <Column field="financial_status" header="Financial Status" />
              <Column
                header="Line Items"
                body={(rowData) => (
                  <ul>
                    {rowData.line_items?.map((item, index) => (
                      <li key={index}>{item.name || "Unnamed Product"}</li>
                    ))}
                  </ul>
                )}
              />
              <Column
                header="Product Vendor"
                body={(rowData) => (
                  <ul>
                    {rowData.line_items?.map((item, index) =>
                      item.vendor ? <li key={index}>{item.vendor}</li> : null
                    )}
                  </ul>
                )}
              />
              <Column
                header="Variant Title"
                body={(rowData) => (
                  <ul>
                    {rowData.line_items?.map((item, index) =>
                      item.variant_title ? <li key={index}>{item.variant_title}</li> : null
                    )}
                  </ul>
                )}
              />
              <Column
                header="Product SKU"
                body={(rowData) => (
                  <ul>
                    {rowData.line_items?.map((item, index) =>
                      item.sku ? <li key={index}>{item.sku}</li> : null
                    )}
                  </ul>
                )}
              />
              <Column
                header="Variant ID"
                body={(rowData) => (
                  <ul>
                    {rowData.line_items?.map((item, index) =>
                      item.variant_id ? <li key={index}>{item.variant_id}</li> : null
                    )}
                  </ul>
                )}
              />
              <Column
                header="Taxable"
                body={(rowData) => (
                  <ul>
                    {rowData.line_items?.map((item, index) => (
                      <li key={index}>{item.taxable ? "Yes" : "No"}</li>
                    ))}
                  </ul>
                )}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
