const serverUrl = process.env.REACT_APP_SERVER_URL;
const shopifyUrl = process.env.REACT_APP_SHOPIFY_URL;
const shopifyToken = process.env.REACT_APP_SHOPIFY_API_KEY;

const myHeaders = new Headers();
myHeaders.append("X-Shopify-Access-Token", `${shopifyToken}`);
myHeaders.append("Content-Type", "application/json");

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const descriptionToUl = (description) => {
  const items = description.split("\\n-");
  let htmlString = "<ul>";
  items.forEach((item) => {
    htmlString += `<li>${item.replace("-", "").trim()}</li>`;
  });
  htmlString += "</ul>";
  return htmlString;
};

const updateInventoryLevels = async (product, variant, myHeaders) => {
  const availReno = product?.available_reno || 0;

  const locationBodyReno = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      inventory_item_id: variant.inventory_item_id,
      location_id: 97295237407,
      available: availReno,
    }),
  };

  const responseReno = await fetch(
    `/proxy.php?url=${encodeURIComponent(shopifyUrl + "/inventory_levels/set.json")}`,
    locationBodyReno
  );
  // const responseReno = await fetch(`${shopifyUrl}/inventory_levels/set.json`, locationBodyReno);
  if (!responseReno.ok) {
    console.error(
      `Failed to update inventory levels for Reno: ${responseReno.status} - ${responseReno.statusText}`
    );
  }
  await delay(2000);

  const availSantaFe = product?.available_santa_fe || 0;

  const locationBodySantaFe = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      inventory_item_id: variant.inventory_item_id,
      location_id: 97295204639,
      available: availSantaFe,
    }),
  };

  const responseSantaFe = await fetch(
    `/proxy.php?url=${encodeURIComponent(shopifyUrl + "/inventory_levels/set.json")}`,
    locationBodySantaFe
  );
  // const responseSantaFe = await fetch(
  //   `${shopifyUrl}/inventory_levels/set.json`,
  //   locationBodySantaFe
  // );
  if (!responseSantaFe.ok) {
    console.error(
      `Failed to update inventory levels for Santa Fe: ${responseSantaFe.status} - ${responseSantaFe.statusText}`
    );
  }
  await delay(2000);
};

const updateVariantImage = async (imageId, variant, myHeaders) => {
  const requestBodyUpdateVariantImages = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify({
      variant: {
        id: `${variant.id}`,
        image_id: `${imageId}`,
      },
    }),
  };

  const response = await fetch(
    `/proxy.php?url=${encodeURIComponent(
      shopifyUrl + "/products/" + variant.product_id + "/variants/" + variant.id + ".json"
    )}`,
    requestBodyUpdateVariantImages
  );
  // const response = await fetch(
  //   `${shopifyUrl}/products/${variant.product_id}/variants/${variant.id}.json`,
  //   requestBodyUpdateVariantImages
  // );
  if (!response.ok) {
    throw new Error(`Failed to update variant images: ${response.status} - ${response.statusText}`);
  }
  return await response.json();
};

const updateVariants = async (result, product) => {
  let updateMyDb = [];
  result.product.variants.forEach(async (variant, index) => {
    try {
      updateMyDb.push({
        variant_id: variant.id,
        product_id: variant.product_id,
        inventory_item_id: variant.inventory_item_id,
        sku: variant.sku,
      });

      await updateVariantImage(result.product.images[index].id, variant, myHeaders);
      await delay(2000);
      await updateInventoryLevels(product, variant, myHeaders);
    } catch (error) {
      console.error(error.message);
    }
  });
  return updateMyDb;
};

export const uploadShopifyProduct = async (product) => {
  const variants = {
    title: product.item_description,
    price: product.msrp !== 0 ? product.msrp : (product.your_price / 0.6).toFixed(2),
    cost: product.your_price,
    sku: product.id,
    image: {
      src: `http://www.bti-usa.com/images/pictures${product.image_path}`,
    },
    inventory_policy: "continue",
    fulfillment_service: "manual",
    inventory_management: "shopify",
    option1: product.item_description,
    barcode: product.upc || product.ean,
    metafields: [
      {
        namespace: "custom",
        key: "map_pricing",
        value: product.map,
        type: "string",
      },
      {
        namespace: "custom",
        key: "bulk_item_fee",
        value: product.oversize_surcharge,
        type: "string",
      },
      {
        namespace: "custom",
        key: "is_on_sale",
        value: product.is_on_sale === "0" ? false : true,
        type: "boolean",
      },
    ],
  };

  const productImages = {
    src: `http://www.bti-usa.com/images/pictures${product.image_path}`,
  };

  const shopifyProduct = {
    product: {
      title: product.group_description.replaceAll("\\n", " "),
      body_html: "<p>" + descriptionToUl(product.group_text) + "</p>",
      vendor: product.manufacturer_name,
      product_type: product.category_name,
      tags: product.sub_category_name,
      status: "active",
      metafields: [
        {
          namespace: "custom",
          key: "is_bti",
          value: true,
          type: "boolean",
        },
      ],
      variants: [variants],
      options: [
        {
          name: "description",
          position: 1,
          values: product.item_description,
        },
      ],
      images: [productImages],
    },
  };

  const rawBodyShopifyProduct = JSON.stringify(shopifyProduct);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: rawBodyShopifyProduct,
    redirect: "follow",
  };

  const response = await fetch(
    `/proxy.php?url=${encodeURIComponent(shopifyUrl + "/products.json")}`,
    requestOptions
  );
  // const response = await fetch(`${shopifyUrl}/products.json`, requestOptions);
  const result = await response.json();
  await delay(2000);
  const uploadedVariants = await updateVariants(result, product);
  await delay(2000);
  await updateMyDBWithShopify(uploadedVariants);

  return {
    status: response.status,
    response: result,
    uploadedVariants,
  };
};

const addNewVariant = async (variant, result) => {
  let updateMyDb = [];
  try {
    const requestBody = JSON.stringify({
      variant: {
        title: variant.item_description,
        price: variant.msrp !== 0 ? variant.msrp : (variant.your_price / 0.6).toFixed(2),
        cost: variant.your_price,
        sku: variant.id,
        image_id: result.image.id,
        inventory_policy: "continue",
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: variant.item_description,
        barcode: variant.upc || variant.ean,
        metafields: [
          {
            namespace: "custom",
            key: "map_pricing",
            value: variant.map,
            type: "string",
          },
          {
            namespace: "custom",
            key: "bulk_item_fee",
            value: variant.oversize_surcharge,
            type: "string",
          },
          {
            namespace: "custom",
            key: "is_on_sale",
            value: variant.is_on_sale === "0" ? false : true,
            type: "boolean",
          },
        ],
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: requestBody,
      redirect: "follow",
    };

    const response = await fetch(
      `/proxy.php?url=${encodeURIComponent(
        shopifyUrl + "/products/" + variant.product_id + "/variants.json"
      )}`,
      requestOptions
    );
    // const response = await fetch(
    //   `${shopifyUrl}/products/${variant.product_id}/variants.json`,
    //   requestOptions
    // );
    const addNewVariantResponse = await response.json();
    const new_variant = addNewVariantResponse.variant;

    await delay(2000);
    updateMyDb.push({
      variant_id: new_variant.id,
      product_id: new_variant.product_id,
      inventory_item_id: new_variant.inventory_item_id,
      sku: new_variant.sku,
    });

    await delay(2000);
    await updateInventoryLevels(variant, new_variant, myHeaders);

    return {
      addNewVariantResponse,
      updateMyDb,
    };
  } catch (error) {
    console.log(error);
  }
};

export const uploadShopifyVariant = async (variant) => {
  try {
    const requestBody = JSON.stringify({
      image: {
        src: `http://www.bti-usa.com/images/pictures${variant.image_path}`,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: requestBody,
      redirect: "follow",
    };
    const response = await fetch(
      `/proxy.php?url=${encodeURIComponent(
        shopifyUrl + "/products/" + variant.product_id + "/images.json"
      )}`,
      requestOptions
    );
    // const response = await fetch(
    //   `${shopifyUrl}/products/${variant.product_id}/images.json`,
    //   requestOptions
    // );
    const result = await response.json();
    await delay(2000);
    const new_variant = await addNewVariant(variant, result);
    await delay(2000);
    await updateMyDBWithShopify(new_variant.updateMyDb);

    return {
      status: response.status,
      response: new_variant.addNewVariantResponse,
      updateMyDb: new_variant.updateMyDb,
    };
  } catch (error) {
    console.log(error);
  }
};

const updateMyDBWithShopify = async (updateMyDb) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ updateMyDb }),
    redirect: "follow",
  };

  const response = await fetch(`${serverUrl}/update_my_db.php`, requestOptions);
  if (!response.ok) {
    console.error(`Failed to update DB: ${response.status} - ${response.statusText}`);
  }
  return await response.json();
  // return updateMyDb;
};
