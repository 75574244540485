import React, { useState, useContext } from "react";
import { Alert, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const LoginPage = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setMessage("");
      setLoading(true);

      const response = await fetch(`${serverUrl}/login.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!data.error) {
        toast.success("Logged in successfully");
        login(data.token, data.user_id);
        navigate("/all-products");
      } else {
        setMessage(data.error_msg);
        setLoading(false);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="account-body accountbg">
      <div className="container">
        <div className="row vh-100 pt-5">
          <div className="col-12 align-self-center">
            <div className="auth-page">
              <div className="card auth-card shadow-lg">
                <div className="card-body">
                  <div className="px-3">
                    <div className="auth-logo-box">
                      <a className="logo logo-admin">
                        <img src="/GC_logo.png" height="55px" alt="logo" className="auth-logo" />
                      </a>
                    </div>
                    <div className="text-center auth-logo-text">
                      <h4 className="mt-0 mb-3 mt-5">Sign in to continue</h4>
                    </div>
                    {message && (
                      <Alert severity={message.startsWith("Welcome") ? "success" : "error"}>
                        {message}
                      </Alert>
                    )}
                    <form
                      className="form-horizontal auth-form my-4"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="form-group">
                        <label className="d-flex">Email</label>
                        <div className="input-group mb-3">
                          <span className="auth-form-icon">
                            <i className="dripicons-user"></i>
                          </span>
                          <input
                            type="email"
                            autoComplete="email"
                            autoFocus
                            required
                            className="form-control"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="d-flex">Password</label>
                        <div className="input-group mb-3">
                          <span className="auth-form-icon">
                            <i className="dripicons-lock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            autoComplete="current-password"
                            value={password}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row mt-4">
                        <div className="col-sm-6 text-left">
                          <a href="/forgot-password" className="text-muted font-13">
                            <i className="dripicons-lock"></i> Forgot password?
                          </a>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <>
                                Log In
                                <i className="fas fa-sign-in-alt ml-1"></i>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
